<template>
  <div class="container bg-white mx-auto">
    <div class="flex flex-col lg:flex-row items-center">
      <div class="w-1/2 hidden lg:block">
        <img src="../assets/images/GabrielleNadeau-Headshot-BW-Web.jpg" alt="Gabrielle Nadeau portrait" />
      </div>
      <div class="w-auto lg:w-1/2 p-8">
        <h1 class="text-3xl md:text-4xl lg:text-6xl font-black text-coolGray-800">Hello!</h1>
        <p class="mt-6 text-2xl lg:text-3xl font-black text-coolGray-800">
          I’m <span class="text-cyan-600">Gabrielle Nadeau</span>, a Canadian author and illustrator, visual
          artist, filmmaker and
          <span class="text-cyan-600">mother of 2!</span>
        </p>
        <img
          src="../assets/images/GabrielleNadeau-Headshot-BW-Web.jpg"
          alt="Gabrielle Nadeau portrait"
          class="w-auto md:w-3/4 lg:hidden mx-auto mt-6"
        />
        <p class="mt-8 text-base md:text-xl">
          My life is currently being surrounded with little monsters: my illustrated ones and my two very own
          at home. I LOVE writing and illustrating funny monsters that make kids laugh! It’s all about fun!
        </p>
      </div>
    </div>
    <!-- HERO SECTION ENDS -->
    <div class="px-8 py-8 sm:px-12 sm:py-8 xl:p-16">
      <h2 class="text-xl md:text-2xl lg:text-3xl font-black text-coolGray-800">
        Want to know more about me?
      </h2>
      <p class="mt-6 text-base md:text-xl">
        Well, I’d like to say that a bright light shined upon me when I was born, and it enlightened me to my
        one true passion of being an author / illustrator. But the truth is, my life has been filled with
        multiple interests that I have pursued at different times. These passions have filled my life with joy
        and adventures!
      </p>
      <p class="mt-6 text-base md:text-xl">
        I was born and raised in Lévis, Québec, where I discovered my first passion: painting. I started with
        acrylics at about 9 years old. I painted landscapes, animals, surrealist scenes (ahhh… high school),
        and then I fell in love with portraitures using oil on canvas. Throughout my life, I have always had a
        painting on the go.
      </p>
      <p class="mt-6 text-base md:text-xl">
        As a teen, I was interested in French literature and watching movies. I had never touched a camera in
        my life but I decided to make filmmaking my career! I left my home town to learn English and attend
        York University in Toronto. I obtained a Bachelor of Fine Arts in Film and Video Production and worked
        in the film industry for fifteen years. I worked as a director, a gaffer, a grip, and eventually as a
        drone camera operator with my husband. We traveled the world together working for BBC, Universal,
        Paramount, Warner Brothers, etc. on all types of projects, television series, commercials,
        documentaries and feature films. I camped in Siberia, I trekked the jungles of Papua New Guinea, I
        filmed in deserts, savannahs, wetlands, oceans, mountains. I had a wonderful career and I discovered a
        real passion for travelling!
      </p>
      <p class="mt-6 text-base md:text-xl">
        After years of travels, my husband and I wanted to slow down and start a family. We have been blessed
        with 2 children, a boy and a girl. Being around them has inspired me to explore the world of
        children’s literature. I have discovered a real love for writing and illustrating that fills me with
        excitement, possibilities and lots and lots of stories.
      </p>
      <h2 class="text-xl md:text-2xl lg:text-3xl mt-12 font-black text-coolGray-800">
        Other fun facts about me!
      </h2>
      <p class="mt-6 text-base md:text-xl">
        I am also a woodworker and a seamstress. I love wrapping presents, I used to sing and play accordion
        on the side for an indie rock band… and I also used to be a part-time clown during university. I still
        face paint and make balloon animals for my children.
      </p>
    </div>
  </div>
</template>
